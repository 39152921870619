$( document ).ready(function() {

    /*
    * buy membership
    *
    **/
    $("#member_payment_method").on("change", function() {
        if ( $(this).val() == 'coupon' ) {
            $("#member_coupon_used").show();
            $("#membership-pay-coupon").show();
            $("#membership-pay-itself").hide();
        } else {
            $("#member_coupon_used").hide();
            $("#membership-pay-coupon").hide();
            $("#membership-pay-itself").show();
        }
    });


    if ( $("#member_payment_method").val() == 'coupon' ) {
        $("#member_coupon_used").show();
        $("#membership-pay-coupon").show();
        $("#membership-pay-itself").hide();
    } else {
        $("#member_coupon_used").hide();
        $("#membership-pay-coupon").hide();
        $("#membership-pay-itself").show();
    }

   /*
    * renew membership
    *
    **/
   if ( $("#member_renew__token").val() != null ) {
       $("#member_renew_payment_method").on("change", function () {
           if ($(this).val() == 'coupon') {
               $("#payment_prepayment").hide();
               $("#member_renew_coupon_used").show();
               $("#membership-pay-coupon").show();
               $("#membership-pay-itself").hide();
               $("#member_renew_Bezahlen").show();
           }
           if ($(this).val() == 'prepayment' ) {
               $("#payment_prepayment").show();
               $("#member_renew_Bezahlen").hide();
               $("#member_renew_coupon_used").hide();
               $("#membership-pay-coupon").hide();
               $("#membership-pay-itself").show();
           }
           if ($(this).val() != 'prepayment' && $(this).val() != 'coupon') {
               $("#payment_prepayment").hide();
               $("#member_renew_coupon_used").hide();
               $("#membership-pay-coupon").hide();
               $("#membership-pay-itself").show();
               $("#member_renew_Bezahlen").show();
           }
       });

       if ($("#member_renew_payment_method").val() == 'coupon') {
           $("#payment_prepayment").hide();
           $("#member_renew_coupon_used").show();
           $("#membership-pay-coupon").show();
           $("#membership-pay-itself").hide();
           $("#member_renew_Bezahlen").show();
       }
       if ($("#member_renew_payment_method").val() == 'prepayment' ) {
           $("#payment_prepayment").show();
           $("#member_renew_Bezahlen").hide();
           $("#member_renew_coupon_used").hide();
           $("#membership-pay-coupon").hide();
           $("#membership-pay-itself").show();
       }
       if ($("#member_renew_payment_method").val() != 'prepayment' && $("#member_renew_payment_method").val() != 'coupon') {
           $("#payment_prepayment").hide();
           $("#member_renew_coupon_used").hide();
           $("#membership-pay-coupon").hide();
           $("#membership-pay-itself").show();
           $("#member_renew_Bezahlen").show();
       }
   }



    /*
    * donate membership
    *
    **/
    $("#membership_donate_ordered_quantity").on("change", function() {
        $(".product-quantity").html(($(this).val()));
        $(".totalcost").html(($(this).val())*49);
    });

    $(".totalcost").html(
        $("#membership_donate_ordered_quantity").val()*49
    );
    $(".product-quantity").html(
        $("#membership_donate_ordered_quantity").val()
    );


    /* NAVIGATION */
    $("#main-navi .tab-pane > div").each(function(){
        var width = 0;

        $(this).find("a").each(function(){
            width += $(this).outerWidth(true);
        });

        if(width>0) {
            $(this).width(width);
        } else {
            $(this).addClass("empty");
        }
    });

    if($("#main-navi .tab-pane.active a.active").length>0) {
        var $target = $("#main-navi .tab-pane.active a.active");
        var halfTargetWidth = $target.outerWidth(true) / 2;
        var halfScreenWidth = $(window).width() / 2;
        var positionLeft = $target.position().left;
        var left = positionLeft - halfScreenWidth + halfTargetWidth;
        $("#main-navi .tab-pane.active").scrollLeft(left);
        $("#main-navi").addClass("item-selected");
    }

    $("#main-navi ul.nav li:not(.secured) a").on("click",function(){
        $("#main-navi").toggleClass("item-selected");
    });

    $("#menu-button").click(function(){
        $("#main-navi").removeClass("item-selected");
    });

    $("#kommende_events .no-events span").click(function(){
        $("#link-rueckblick_bilder").tab("show");
    });

    if($("#main-navi .nav li.active").length>0) {
        $("#main-navi .tab-content").addClass("open");
    } else {
        $("#main-navi ul.nav li a").on("show.bs.tab",function(){
            $("#main-navi .tab-content").addClass("open");
        });
    }

    $("#link-rueckblick_bilder").click(function(){
        $("#link-rueckblick_bilder").tab("show");
    });
    $("#link-kommende_events").click(function(){
        $("#link-kommende_events").tab("show");
    });
    $("#link-fan_news").click(function(){
        $("#link-fan_news").tab("show");
    });
    $("#main-navi-scroll-right").click(function(){
        var left = $(".tab-content>.active").scrollLeft();
        var scrollStep = $(".tab-content>.active").width() / 100 * 90;
        $(".tab-content>.active").stop().animate({
            scrollLeft: left + scrollStep
        },1000);
    });

    $("#main-navi-scroll-left").click(function(){
        var left = $(".tab-content>.active").scrollLeft();
        var scrollStep = $(".tab-content>.active").width() / 100 * 90;
        $(".tab-content>.active").stop().animate({
            scrollLeft: left - scrollStep
        },1000);
    });

    $("#menu-button").on('click', function() {
        event.preventDefault();
        $([document.documentElement, document.body]).animate({
            scrollTop: $("#main-navi").offset().top
        }, 850);

    });



    $(window).on("resize",function(){
        handleScrollButtons();
    });

    $("#main-navi ul.nav li a").on("shown.bs.tab",function(){
        handleScrollButtons();
    });

    $(".tab-content>div").on("scroll",function(){
        handleScrollButtons();
    });

    handleScrollButtons();

    function handleScrollButtons(){
        var scrollLeft = $(".tab-content>.active").scrollLeft();

        if(scrollLeft>100) {
            $("#main-navi-scroll-left").addClass("in");
        } else {
            $("#main-navi-scroll-left").removeClass("in");
        }

        if(scrollLeft<$(".tab-content>.active>div").width()-$(window).width()-100) {
            $("#main-navi-scroll-right").addClass("in");
        } else {
            $("#main-navi-scroll-right").removeClass("in");
        }
    }

    function wow(){
        var wow = new WOW(
            {
                boxClass:     'wow',      // animated element css class (default is wow)
                animateClass: 'animated', // animation css class (default is animated)
                offset:       0,          // distance to the element when triggering the animation (default is 0)
                mobile:       true,       // trigger animations on mobile devices (default is true)
                live:         true,       // act on asynchronously loaded content (default is true)
                callback:     function(box) {
                    // the callback is fired every time an animation is started
                    // the argument that is passed in is the DOM node being animated
                }
            }
        );
        wow.init();
    }

    if($("#main-navi .nav li.active").length>0) {
        $("#main-navi .tab-content").addClass("open");
    } else {
        $("#main-navi ul.nav li a").on("show.bs.tab",function(){
            $("#main-navi .tab-content").addClass("open");
        });
    }

});